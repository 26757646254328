// src/components/functional/Prices/statesCanadaData.js

export const statesCanada = [
    { value: "CA", label: "Canada Federal (CA)", price: 59, surcharge: 9 },
    { value: "CA-AB", label: "Alberta (CA-AB)", price: 59, surcharge: 9 },
    { value: "CA-BC", label: "British Columbia (CA-BC)", price: 59, surcharge: 9 },
    { value: "CA-MB", label: "Manitoba (CA-MB)", price: 59, surcharge: 9 },
    { value: "CA-NB", label: "New Brunswick (CA-NB)", price: 59, surcharge: 9 },
    { value: "CA-NL", label: "Newfoundland and Labrador (CA-NL)", price: 59, surcharge: 9 },
    { value: "CA-NT", label: "Northwest Territories (CA-NT)", price: 59, surcharge: 9 },
    { value: "CA-NS", label: "Nova Scotia (CA-NS)", price: 59, surcharge: 9 },
    { value: "CA-NU", label: "Nunavut (CA-NU)", price: 59, surcharge: 9 },
    { value: "CA-ON", label: "Ontario (CA-ON)", price: 59, surcharge: 9 },
    { value: "CA-PE", label: "Prince Edward Island (CA-PE)", price: 59, surcharge: 9 },
    { value: "CA-QC", label: "Quebec (CA-QC)", price: 59, surcharge: 9 },
    { value: "CA-SK", label: "Saskatchewan (CA-SK)", price: 59, surcharge: 9 },
    { value: "CA-YT", label: "Yukon (CA-YT)", price: 59, surcharge: 9 }
];